import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, NamedLink } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import IconCard from '../SavedCardDetails/IconCard/IconCard';
import css from './SecondaryBar.module.css';

const rightSideButtons = [
  {
    label: 'How it Works',
    name: 'how-it-works',
  },
  {
    label: 'How to Sell',
    name: 'how_to_sell',
  },
  {
    label: 'About',
    name: 'about',
  },
  {
    label: 'Help',
    name: 'help',
  },
];

function SecondaryBar(props) {
  const config = useConfiguration();
  const categories = config?.categoryConfiguration?.categories ?? [];
  const { onOpenMobileMenu, currentUser } = props;

  if (categories.length === 0) {
    return null;
  }

  const { isAuthenticated } = useSelector(state => state).auth;

  return (
    <div className={css.secondaryTopbar}>
      <div className={css.desktopLeft}>
        {[...categories, { id: 'sale', name: 'Sale' }].map((category, index) => {
          return (
            <div key={index}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    category.id === 'sale'
                      ? `?pub_discountAvailable=yes`
                      : `?pub_categoryLevel1=${category.id}`,
                }}
              >
                {category.name}
              </NamedLink>
            </div>
          );
        })}
      </div>
      <div className={css.desktopRight}>
        {rightSideButtons.map((button, index) => {
          return (
            <div key={index}>
              <NamedLink name="CMSPage" params={{ pageId: button.name }}>
                {button.label}
              </NamedLink>
            </div>
          );
        })}
      </div>
      <div className={css.mobileLeft}>
        <NamedLink name="NewListingPage" className={css.listItemButton}>
          List an Item
        </NamedLink>
      </div>
      <div className={css.mobileRight}>
        <NamedLink name="FavoriteListingsPage">
          <IconCard brand="heart" />
        </NamedLink>
        <NamedLink name="InboxBasePage">
          <IconCard brand="mail" />
        </NamedLink>
        {isAuthenticated ? (
          <div onClick={onOpenMobileMenu}>
            <Avatar user={currentUser} disableProfileLink />
          </div>
        ) : (
          <div className={css.loginSignupWrapper}>
            <NamedLink name="LoginPage">Login</NamedLink>
            <span>|</span>
            <NamedLink name="SignupPage">Signup</NamedLink>
          </div>
        )}
      </div>
    </div>
  );
}
export default SecondaryBar;
